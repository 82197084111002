"use client"
import {IImageComponent} from '../../../lib/types/common';
import {nextImageLoader} from '../../../lib/utils/constants/PagesConstants';
import React from "react";
import Image from "next/legacy/image";
import { gumletLoader } from '../../../GumletLoader';

const CustomImageComponent = (props: IImageComponent) => {
    const {
        layout,
        objectFit,
        src,
        alt,
        priority = false,
        height,
        width,
        objectPosition,
        className
    } = props;

    return (
        <>
            {src ?
                <Image
                    loader={gumletLoader}
                    // fill={layout === "fill"}
                    sizes={(layout === "fill" || layout === "responsive") ? "(max-width: 768px) 100vw, (max-width: 1200px) 100vw, 100vw" : undefined}
                    src={src}
                    alt={alt ? alt : ""}
                    priority={priority}
                    height={height}
                    width={width}
                    objectPosition={objectPosition}
                    objectFit={objectFit}
                    layout={layout || "fill"}
                    // style={{
                    //     objectFit: objectFit,
                    //     objectPosition: objectPosition,
                    // }}
                    className={className}
                />
                :
                <></>}
        </>

    )
}

export default CustomImageComponent;