import { ISideDrawerComponentProps } from "../../../lib/types/components";
import {
  publishWithUsType,
  sendPublishWithUsEvent,
} from "../../../lib/utils/constants/AnalyticsConstants";
import { GDC_WEB_URL } from "../../../lib/utils/constants/GlobalConstants";
import CustomLinkComponent from "../CusotmLink/CustomLinkComponent";
import CustomImageComponent from "../CustomImage/CustomImageComponent";
import styles from "./SideDrawerComponentV2.module.css";
import { multipleClassName } from "../../../lib/utils/helpers/helper";
import { useState } from "react";
import { INavHeadingDataLinks } from "../../../lib/types/common";
import { useDownloadContext } from "../../../lib/context/CommonContext";

const SideDrawerComponentV2 = (props: ISideDrawerComponentProps) => {
  const { navigationDataLinksV2 } = props;
  const {handleHamburgerClick, menuOpen} = useDownloadContext()
  const [openSubHeading, setOpenSubHeading] = useState<number | null>(null);

  const dropDownArrow =
    "https://d3g4wmezrjkwkg.cloudfront.net/website/images/dropDownArrow.webp";
  
  const navData = navigationDataLinksV2;

  const handleSubHeadingClick = (index:number) => {
    if (openSubHeading === index) {
      setOpenSubHeading(null);
    } else {
      setOpenSubHeading(index);
    }
  };

  return navData ? (
    <>
      <aside
        className={styles.drawer}
        style={menuOpen ? { width: "100%" } : { width: 0 }}
        onClick={() => handleHamburgerClick()}
      >
        <div className={styles.customScrollbar}>
          <nav
            className={styles.navbar}
            style={
              menuOpen
                ? { transition: "opacity 0.5s ease-in", opacity: 0.99 }
                : { opacity: 0 }
            }
          >
            <div
              className={multipleClassName(
                "container-fluid",
                styles.menuContainer
              )}
            >
              {navData.map((data) => (
                <div>
                  <div className={multipleClassName("row", styles.gameRow)}>
                    <div className={multipleClassName("col", styles.imgCol)}>
                      <div className={styles.imgContainer1}>
                        <CustomImageComponent
                          layout={"fill"}
                          objectFit={"contain"}
                          src={data.img}
                          alt={"game icon image"}
                        />
                      </div>
                    </div>

                    {data.url ? (
                      <div
                        onClick={() => {
                          data.url === GDC_WEB_URL &&
                            sendPublishWithUsEvent(publishWithUsType.MOBILE);
                        }}
                        className={multipleClassName(
                          "col",
                          styles.headingLinkContainer
                        )}
                      >
                        <CustomLinkComponent
                          className={styles.navHeadingClickable}
                          href={data.url}
                          mobStyle={{
                            textDecoration: "none",
                          }}
                          desktopStyle={{
                            textDecoration: "none",
                          }}
                        >
                          {data.heading}
                        </CustomLinkComponent>
                      </div>
                    ) : (
                      <div
                        className={multipleClassName("col", styles.heading)}
                      >
                        {data.heading}
                      </div>
                    )}
                  </div>
                  {data.alwaysVisible===true ? (
                    <div className={styles.subLinksContainer}>
                      {data.navLinks.map((navLink) =>
                        navLink.links.map((link) => (
                          <span
                            className={styles.clickInside}
                          >
                            <CustomLinkComponent
                              href={link.link}
                              mobStyle={{
                                color: "#d8d8d8",
                                textDecoration: "none",
                              }}
                              desktopStyle={{
                                color: "#d8d8d8",
                                textDecoration: "none",
                              }}
                            >
                              {link.title}
                            </CustomLinkComponent>
                          </span>
                        ))
                      )}
                    </div>
                  ) : (
                    data.navLinks.length > 0 &&
                    data.navLinks.map((item,index) => (
                      <div
                        className={styles.subHeadingBorder}
                      >
                        <div
                          className={multipleClassName("row", styles.gameRow)}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSubHeadingClick(index);
                          }}
                        >
                          <div
                            className={multipleClassName("col", styles.imgCol)}
                          >
                            <div className={styles.subHeadingImgContainer}>
                              <CustomImageComponent
                                layout={"fill"}
                                objectFit={"contain"}
                                src={item.img}
                                alt={"subheading icon image"}
                              />
                            </div>
                          </div>
                          <div
                            className={multipleClassName("col", styles.navItem)}
                          >
                            {item.subHeading}
                          </div>
                          <div
                            className={multipleClassName("col", styles.imgCol)}
                          >
                            <div className={multipleClassName(styles.imgContainer1,styles.clickableArrow)}>
                              <CustomImageComponent
                                layout={"fill"}
                                objectFit={"contain"}
                                src={dropDownArrow}
                                alt={"subHeading dropDown"}
                                className={
                                  openSubHeading === index
                                    ? `${styles.rotateArrow} ${styles.rotate}`
                                    : styles.rotateArrow
                                }
                              />
                            </div>
                          </div>
                          {openSubHeading === index && (
                            <div className={styles.gameRow}>
                            <div className={styles.subLinksContainer}>
                              {item.links.map((link) =>
                                link.outside ? (
                                  <a
                                    href={link.link}
                                    className={styles.clickOutside}
                                  >
                                    {link.title}
                                  </a>
                                ) : (
                                  <span
                                    className={styles.clickInside}
                                  >
                                    <CustomLinkComponent
                                      href={link.link}
                                      mobStyle={{
                                        color: "#d8d8d8",
                                      }}
                                      desktopStyle={{
                                        color: "#d8d8d8",
                                      }}
                                      >
                                      {link.title}
                                    </CustomLinkComponent>
                                  </span>
                                )
                              )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))
                  )}
                </div>
              ))}
            </div>
          </nav>
        </div>
      </aside>
    </>
  ) : (
    <></>
  );
};

export default SideDrawerComponentV2;
