import Analytics from "../../analytics/Analytics";
import { getPublishWithUsEvent } from "../../analytics/AnalyticsEvents";

export const publishWithUsType = {
    DESKTOP: "desktop",
    MOBILE: "mobile"
}

export const sendPublishWithUsEvent = (type: string): void => {
    let event = getPublishWithUsEvent(type); // set event name 
    Analytics.getInstance().logEvent(event);
}