"use client"
import {useEffect, useState} from "react";

const useIsMobile = () => {
    const [isMobileDevice, setIsMobileDevice] = useState<boolean>(
        true
    );
    const [windowWidth, setWindowWidth] = useState(577)

    const onResize = () => {
        setWindowWidth(window.innerWidth)
        if (window.innerWidth < 767) {
            setIsMobileDevice(true);
        } else {
            setIsMobileDevice(false);
        }
    }

    useEffect(() => {
        window.addEventListener("resize", onResize)
        onResize()

        return () => {
            window.removeEventListener("resize", onResize)
        }
    }, []);
    return {isMobileDevice, windowWidth};
};
export default useIsMobile;
