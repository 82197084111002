import { ImageLoader, ImageLoaderProps } from "next/image";

export const gumletLoader: ImageLoader = ({
    src,
    width,
    quality,
}: ImageLoaderProps) => {
    if (width) {
        return `${src}?w=${width}&q=${quality || 80}`;
    } else {
        return `${src}?q=${quality || 80}`;
    }  
};